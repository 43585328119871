.App {
  text-align: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.App-header {
  padding: 20px;
  color: #153608;
  position: relative;
}

.logo {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;
  max-width: 200px;
}

h1 {
  margin: 0;
  font-size: 48px;
  /* font-family: 'Prata', cursive; */
}

.title {
  font-size: 60px;
  font-family: "DM Serif Display";
  font-weight: 100;
  color: #CD246F;
}

.names {
  font-size: 30px;
  font-family: 'DM Serif Display', cursive;
  font-weight: 100;
  color: #710E3A;
}

.navbuttons {
  display: flex;
  justify-content: right;
}

.navbutton {
  background-color: white;
  color: #00687a;
  text-decoration: underline;
  padding: 10px 20px;
  margin-right: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: right;
}

.wedding-quote {
  font-size: 20px;
  margin: 20px 0;
  font-style: italic;
  font-family: 'DM Serif Display', sans-serif;
  color: #710E3A;
}

.wedding-image {
  padding-top: 100px;
  width: 100%;
  max-width: 300px;
  margin: 50px 0;
  border-radius: 10px;
  /* opacity: 0.6; Faded effect */
}

.rsvp-section {
  background-color: hsl(0, 0%, 100%);
  border: 2px solid #710E3A;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 50px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rsvp-title {
  margin: 20px 0;
  font-size: 35px;
  font-family: "DM Serif Display";
  font-weight: 100;
  color: #710E3A;
}

.rsvp-instructions {
  font-size: 20px;
  font-family: "DM Serif Display";
  font-weight: 50;
  color: #710E3A;
}

.admin-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 2px solid #CD246F;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.admin-button {
  display: flex;
  justify-content: right;
}
/* .admin-button {
  color: #00687a;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: right;
}

.admin-button:hover {
  background-color: #0869ae;
} */
