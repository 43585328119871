/* AdminLogin.css */
.admin-login {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 2px solid #0097B2;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .admin-login h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .admin-login form {
    display: flex;
    flex-direction: column;
  }
  
  .admin-login input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .admin-login button {
    padding: 10px;
    background-color: #0097B2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .admin-login button:hover {
    background-color: #0869ae;
  }
  
  .admin-login p {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
  