/* RSVPForm.css */

.rsvp-form {
  background-color: #ffffff;
  border: 2px solid #9e1653;
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submitted {
  background-color: #e9468f; /* Light blue background when form is submitted */
}

.code-input {
  width: 30%;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.code-submit-button {
  padding: 10px;
  background-color: #CD246F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.welcome-text {
  font-style: italic;
}

.name-title {
  font-size: 25px;
  font-weight: bold;
  font-family: 'Prata', cursive;
  color: #CD246F;
  margin-bottom: 20px;
  margin-top: 5px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  color: #710E3A;
  font-weight: 500;
  font-family: 'Roboto', sans-serif; /* Change to Roboto */
}

.Guest-Name {
  font-size: 40px;
  font-weight: 500;
  margin: -10px 0 10px 0;
  color: #CD246F;
  font-family: 'Imperial Script', sans-serif; 
}

.attending-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.attending-input {
  width: 50px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #CD246F;
}

.all-attending-button {
  background-color: #95938B;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.confirmation-container {
  background-color: #e9468f;
  padding: 20px;
  border-radius: 10px;
}

.thank-you-message {
  font-family: 'DM Serif Display', cursive;
  font-weight: 100;
  color: white;
  margin-top: 10px;
  font-size: 30px;
}

.additional-info {
  font-family: 'DM Serif Display', cursive;
  font-style: italic;
  font-weight: 50;
  font-size: 14px;
  color: white;
  margin-top: 10px;
}

.additional-info.red {
  margin-top: 30px;
  font-style: italic;
  color: rgb(147, 0, 0);
}

.nikah-time {
  font-family: 'DM Serif Display', cursive;
  /* font-weight: bold; */
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #ffe7f2;
}

.dinner-invitation {
  font-family: 'DM Serif Display', cursive;
  font-style: italic;
  font-weight: 50;
  font-size: 14px;
  margin-top: 10px;
  color: white;
}

/* .dinner-location {
  font-family: 'DM Serif Display', cursive;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  color: #007985;
} */

.submit-button {
  background-color: #e9468f;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

.submit-button:disabled {
  background-color: #ccc;
}

.submit-button:hover:not(:disabled) {
  background-color: #d41c6f;
}

.select-dropdown {
  width: 50%; /* Adjust width as needed */
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #CD246F;
  color: #710E3A;
  background-color: #ffffff;
  cursor: pointer;
}

.select-dropdown:focus {
  outline: none;
  border-color: #e9468f;
  box-shadow: 0 0 5px rgba(233, 70, 143, 0.5);
}

.none-attending-button {
  background-color: #ff4848;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.none-attending-button.selected {
  background-color: #ca1212; /* Change color when selected */
  color: #fff;
}
