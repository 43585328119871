/* AddGuest.css */

.add-guest {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border: 2px solid #CD246F;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'DM Serif Display', serif;
  overflow: hidden; /* Ensure no overflow */
}

.add-guest h2 {
  font-family: 'DM Serif Display', serif;
  color: #CD246F;
  font-size: 36px;
  font-weight: 100;
  margin-bottom: 20px;
  text-align: center;
}

.add-guest-form .form-group {
  margin-bottom: 20px;
  text-align: left;
}

.add-guest-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 20px;
  color: #710E3A;
  font-family: 'DM Serif Display', serif;
}

.add-guest-form input,
.add-guest-form select {
  width: 100%; /* Keep input fields within container */
  box-sizing: border-box; /* Include padding in width calculation */
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.add-guest-form input:focus,
.add-guest-form select:focus {
  outline: none;
  border-color: #CD246F;
}

.submit-button {
  background-color: #CD246F;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  font-family: 'DM Serif Display', serif;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  width: 100%; /* Ensure button stays within container */
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #710E3A;
}

.message {
  margin-top: 20px;
  font-size: 18px;
  color: green;
  font-family: 'DM Serif Display', serif;
  text-align: center;
}

.add-guest-form input::placeholder,
.add-guest-form select::placeholder {
  color: #999;
}
