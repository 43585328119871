/* RSVPList.css */

.rsvp-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
}

.summary-box {
  background-color: #f9f9f9;
  border: 2px solid #710E3A;
  border-radius: 8px;
  padding: 15px;
  width: 180px;
  text-align: center;
  font-family: 'DM Serif Display', serif;
  color: #710E3A;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-box p {
  margin: 0;
  font-size: 18px;
}

.summary-box strong {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

/* Customize each box for distinct colors if desired */
.invitations-sent {
  background-color: #e6f7ff;
}

.total-invited {
  background-color: #ffebf0;
}

.total-responded {
  background-color: #e6f9e6;
}

.total-attending {
  background-color: #fff0e6;
}

.total-not-coming {
  background-color: #f9e6ff;
}


.rsvp-list {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid #CD246F;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'DM Serif Display', sans-serif;
}

.rsvp-title {
  font-size: 35px;
  color: #CD246F;
  font-weight: 100;
}

.rsvp-summary {
  font-size: 18px;
  color: #710E3A;
  margin-bottom: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  background-color: #fff;
  color: #710E3A;
  border: 2px solid #CD246F;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

.filter-buttons button.active {
  background-color: #CD246F;
  color: #fff;
}

.rsvp-table {
  width: 100%;
  border-collapse: collapse;
}

.rsvp-table th, .rsvp-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.row-bride {
  background-color: #FDECEF;
}

.row-groom {
  background-color: #EAF7FA;
}

.responded {
  color: #2F9E44; /* Green for responded */
}

.not-responded {
  color: #D00000; /* Red for not responded */
}
