/* Admin.css */

.admin {
  padding: 20px;
  font-family: 'DM Serif Display', serif;
}

.admin-nav-tabs {
  display: flex;
  justify-content: space-evenly;
  max-width: 600px;
  margin: 0 auto 0px auto;
}

.nav-button {
  background-color: #CD246F;
  color: white;
  padding: 15px 0; /* Adjusted padding for a balanced tab height */
  border: none;
  border-radius: 5px 5px 0 0;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 100;
  flex: 1;
  text-align: center;
  transition: background-color 0.3s ease;
}

.nav-button:not(:last-child) {
  border-right: 1px solid #C41D5F;
}

.nav-button.active {
  background-color: #710E3A;
}

.admin-content {
  padding: 20px;
  border: 2px solid #CD246F;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 600px;
  margin: 0 auto;
}

.side-selector {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.side-button {
  background-color: #CD246F;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.side-button.active {
  background-color: #710E3A;
}

.side-button:hover {
  background-color: #A61C54;
}
